// src/App.js
import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./App.css";

function App() {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <div className="App">
      {/* 🌥 Nuages inline SVG */}
      <div className="cloud cloud1">
        <svg viewBox="0 0 64 32" width="200" height="100">
          <ellipse cx="20" cy="20" rx="20" ry="10" fill="#ffffff" opacity="0.3" />
          <ellipse cx="45" cy="15" rx="15" ry="8" fill="#ffffff" opacity="0.3" />
        </svg>
      </div>
      <div className="cloud cloud2">
        <svg viewBox="0 0 64 32" width="200" height="100">
          <ellipse cx="20" cy="20" rx="20" ry="10" fill="#ffffff" opacity="0.3" />
          <ellipse cx="45" cy="15" rx="15" ry="8" fill="#ffffff" opacity="0.3" />
        </svg>
      </div>

      {/* ✨ Étoiles filantes */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          fullScreen: { enable: true, zIndex: -1 },
          background: { color: "#dbeafe" },
          particles: {
            number: { value: 50 },
            color: { value: "#facc15" },
            shape: { type: "circle" },
            opacity: { value: 0.6 },
            size: { value: 2, random: true },
            move: {
              enable: true,
              speed: 2,
              direction: "bottom",
              outModes: { default: "out" },
              trail: {
                enable: true,
                length: 5,
                fill: { color: "#dbeafe" }
              }
            }
          }
        }}
      />

      <header className="App-header">
        <h1 className="title">Capsy</h1>
        <p className="subtitle">Voyagez dans le temps avec vos souvenirs</p>
      </header>

      <div className="timeline">
        <div className="step"><h3>🌱 Inspiration</h3><p>Trouvez l'idée qui mérite de traverser le temps.</p></div>
        <div className="step"><h3>📸 Création</h3><p>Ajoutez vos souvenirs : texte, audio, vidéo, image.</p></div>
        <div className="step"><h3>⏰ Choisissez une date</h3><p>Programmez l'ouverture de votre capsule.</p></div>
        <div className="step"><h3>🔒 Sécurisation</h3><p>Capsule scellée. Elle attend patiemment son heure.</p></div>
      </div>
    </div>
  );
}

export default App;
